<template>
  <div class="number">
    <div class="search-wrap">
      <div class="title">{{ $t("unitMember") }}</div>
      <div class="searchBtn">
        <searchCompany
          genreType="1"
          v-model="queryParams.companyName"
          @search="search"
          class="search-wrap-box"
        />
      </div>
    </div>
    <filterSearch @handleSearch="handleSearch" :isShow="isShow" />
    <div class="main">
      <div>
        <template v-for="(item, index) in companyList">
          <unitItem
            :number="number"
            :key="index"
            :companyInfo="item"
            :iconShow="2"
          />
        </template>
      </div>
      <isLoadingTip v-if="loading" />
      <isAllDataTip
        v-if="companyList.length && total === companyList.length && !loading"
      />
      <noDataImg v-if="companyList.length === 0"></noDataImg>
      <div class="clearfix" v-show="companyList.length">
        <el-pagination
          class="fr"
          style="margin-top: 10px"
          :current-page="currentPage + 1"
          @current-change="handleChange"
          :total="total"
          background
          :page-size="10"
          layout="prev, pager, next"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import searchCompany from "@/projects/siffa/components/company/unit/searchCompany";
import unitItem from "@/projects/hphSociety/components/company/unit/unitItem";
import filterSearch from "~hph/components/common/filterSearch";
import isLoadingTip from "@/baseComponents/isLoadingTip";
import isAllDataTip from "@/baseComponents/isAllDataTip";

export default {
  metaInfo() {
    return {
      title: "会员单位",
      meta: [
        {
          name: "keyWords",
          content: "上海市国际货运代理行业会员单位,上海货代会员单位",
        },
        {
          name: "description",
          content:
            "上 海 市 国 际 货 运 代 理 行 业 协 会 （ shanghai international freight forwarders association）是在我国改革开放不断深化，国际货运代 理业快速发展条件下，于 1992 年 9 月成立，是我国（除港澳台地区外）最早成立的省 市级国际货运代理行业协会。",
        },
      ],
    };
  },
  name: "member",
  components: {
    searchCompany,
    unitItem,
    filterSearch,
    isLoadingTip,
    isAllDataTip,
  },
  data() {
    return {
      currentPage: 0,
      total: 0,
      companyList: [],
      isShow: false,
      number: 2,
      queryParams: {
        companyName: "",
      },
      loading: false,
      filters: {},
    };
  },
  mounted() {
    this.getList();
  },
  // destroyed() {
  //   window.removeEventListener("scroll", this.scrollEvent, false);
  // },
  methods: {
    handleChange(index) {
      this.currentPage = index - 1;
      this.getList();
    },
    handleData(obj) {
      let arr = [];
      for (let i in obj) {
        arr = arr.concat(obj[i]);
      }
      return arr;
    },
    handleCurrentChange() {},
    async getList() {
      this.loading = true;
      let params = {};
      params.start = this.currentPage;
      params.limit = 10;
      params.name = this.queryParams.companyName;
      params.city_id = this.filters.city;
      params.company_credit = this.filters.credit;
      params.genre_ids = this.filters.unitType;
      // params.certificate=20
      params.source = 33;
      params.genre_ids = 1;
      params.vip_type = 2;
      params.user_id = this.USER_ID;
      // params.source_type=1
      let res = await this.$store.dispatch(
        "API_company/getCompanyList",
        params
      );
      if (res.success) {
        res.data.forEach((item) => {
          item.business_classification_list = this.handleData(
            item.business_classification_info
          );
        });
        res.data.forEach((item, index) => {
          if (item.genre_ids.length > 1) {
            item.genre_ids = item.genre_ids.filter((ee) => ee != "1");
          }
        });
        this.companyList = res.data;
        this.total = res.total;
        this.loading = false;
        document.body.scrollIntoView();
      }
    },
    search() {
      this.currentPage = 0;
      this.getList();
    },
    handleSearch(val) {
      this.filters = val;
      this.currentPage = 0;
      this.getList();
    },
    // scrollEvent() {
    //   if (
    //     document.documentElement.scrollTop +
    //       document.documentElement.clientHeight >=
    //     document.body.scrollHeight - 354
    //   ) {
    //     if (this.total != this.companyList.length) {
    //       this.getList(2);
    //     }
    //   }
    // },
  },
};
</script>

<style lang="less" scoped>
.number {
  .search-wrap {
    display: flex;
    justify-content: space-between;
    .search-wrap-box {
      display: flex;
      padding-top: 10px;
      justify-content: space-between;
    }
  }
}
.title {
  font-size: 24px;
  font-weight: bold;
  color: #666666;
  margin-top: 13px;
  margin-bottom: 24px;
}
.main {
  margin-top: 20px;
  min-height: 200px;
  position: relative;
}
</style>
